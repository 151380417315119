.p-accordion-header span {
	width: 100% !important;
}

.p-accordion-tab.p-accordion-tab-active {
	display: grid;
	gap: 1rem;
}

.p-accordion {
	display: grid;
	gap: 1rem;
}

.StepButton-0-2-4.active {
	background-color: rgb(147 51 234) !important;
}

/* .Label-d3-0-2-15 {
    display: block;
    margin-top: 16px;
    text-align: center;
    font-weight: 900 !important;
} */

.Label-0-2-11 {
	font-weight: 900 !important;
	font-size: large;
	color: gray;
}

.StepButtonContent-d3-0-2-9.active {
    color: rgb(255, 255, 255);
	background-color: rgb(147 51 234) !important;

}

.StepButton-d2-0-2-8.completed {
	background-color: rgb(147 51 234) !important;
}

.StepButton-d6-0-2-18.completed {
	background-color: rgb(147 51 234) !important;
}

#RFS-StepButton {
	pointer-events:none;
}

input[type=checkbox]:checked+label span:first-of-type {
	background-color: #10B981;
	border-color: #10B981;
	color: #fff;
}

input[type=checkbox]:checked+label span:nth-of-type(2) {
	/* text-decoration: line-through; */
	color: #9CA3AF;
}